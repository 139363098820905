import mergeImages from 'merge-images'
import React, { useEffect } from 'react'
import Div100vh from 'react-div-100vh'
import { useScreenshot, createFileName } from 'use-react-screenshot'
import useWindowDimensions from '../../shared/hooks/useWindowDimensions'

import { BackgroundConfig } from '../helpers/backgroundHelper'
import { PostProcessingConfig } from '../helpers/postProcessingHelper'
import { SegmentationConfig } from '../helpers/segmentationHelper'
import { SourcePlayback } from '../helpers/sourceHelper'
import useRenderingPipeline from '../hooks/useRenderingPipeline'
import { TFLite } from '../hooks/useTFLite'

type OutputViewerProps = {
  sourcePlayback: SourcePlayback
  backgroundConfig: BackgroundConfig
  segmentationConfig: SegmentationConfig
  postProcessingConfig: PostProcessingConfig
  tflite: TFLite
  onImageReady?: (e: any) => void
}

function OutputViewer(props: OutputViewerProps) {
  const { pipeline, backgroundImageRef, canvasRef } = useRenderingPipeline(
    props.sourcePlayback,
    props.backgroundConfig,
    props.segmentationConfig,
    props.tflite
  )

  const ref = React.useRef(null)

  const [image, takeScreenShot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  })

  const downloadScreenshot = () =>
    takeScreenShot(ref.current).then(
      (x: any) => props.onImageReady && props.onImageReady(x)
    )

  useEffect(() => {
    if (pipeline) {
      pipeline.updatePostProcessingConfig(props.postProcessingConfig)
    }
  }, [pipeline, props.postProcessingConfig])

  return (
    <Div100vh>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          ref={ref}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {props.backgroundConfig.type === 'image' && (
            <img
              ref={backgroundImageRef}
              style={{
                position: 'absolute',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              }}
              src={props.backgroundConfig.url}
              alt=""
              hidden={props.segmentationConfig.pipeline === 'webgl2'}
            />
          )}
          <div
            style={{ height: '100%', position: 'relative', overflow: 'hidden' }}
          >
            <canvas
              style={{
                height: '100%',
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
              key={props.segmentationConfig.pipeline}
              ref={canvasRef}
              width={props.sourcePlayback.width}
              height={props.sourcePlayback.height}
            />
          </div>
          <img
            style={{
              position: 'absolute',
              objectFit: 'cover',
              bottom: -2,
              width: '100%',
            }}
            src={'/images/book.png'}
            alt=""
          />
        </div>

        <div
          onClick={() => downloadScreenshot()}
          style={{
            position: 'absolute',
            bottom: '16px',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <img src="images/shutter.png" width="72" height="72" />
        </div>
      </div>
    </Div100vh>
  )
}

export default OutputViewer
