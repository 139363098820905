import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { SourceConfig, SourcePlayback } from '../helpers/sourceHelper'

type SourceViewerProps = {
  sourceConfig: SourceConfig
  onLoad: (sourcePlayback: SourcePlayback) => void
}

function SourceViewer(props: SourceViewerProps) {
  const [sourceUrl, setSourceUrl] = useState<string>()
  const [isLoading, setLoading] = useState(false)
  const [isCameraError, setCameraError] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    setSourceUrl(undefined)
    setLoading(true)
    setCameraError(false)

    setTimeout(() => setSourceUrl(props.sourceConfig.url))
  }, [props.sourceConfig])

  useEffect(() => {
    async function getCameraStream() {
      try {
        const constraint = { video: true }
        const stream = await navigator.mediaDevices.getUserMedia(constraint)
        if (videoRef.current) {
          videoRef.current.srcObject = stream
          return
        }
      } catch (error) {
        console.error('Error opening video camera.', error)
      }
      setLoading(false)
      setCameraError(true)
    }

    if (props.sourceConfig.type === 'camera') {
      getCameraStream()
    } else if (videoRef.current) {
      videoRef.current.srcObject = null
    }
  }, [props.sourceConfig])

  function handleVideoLoad(event: SyntheticEvent) {
    const video = event.target as HTMLVideoElement
    props.onLoad({
      htmlElement: video,
      width: video.videoWidth,
      height: video.videoHeight,
    })
    setLoading(false)
  }

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLoading && 'Loading'}
      <video
        ref={videoRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        src={sourceUrl}
        hidden={isLoading}
        autoPlay
        playsInline
        controls={false}
        muted
        loop
        onLoadedData={handleVideoLoad}
      />
    </div>
  )
}

export default SourceViewer
