import React from 'react'
import { useHistory } from 'react-router-dom'

export default function SplashScreen() {
  const [isAgreed, setIsAgreed] = React.useState(false)
  const [pressedStart, setPressedStart] = React.useState(false)
  const history = useHistory()

  return (
    <div
      id="splash-screen"
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        zIndex: 90000124,
        backgroundColor: '#f0f0f0',
        backgroundImage: `url(${'/images/splash.png'}) `,
        backgroundPosition: '0% 24%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflowY: 'scroll',
        color: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        id="splash-screen-container"
        style={{
          height: '100%',
          userSelect: 'none',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          id="logo-container"
          style={{
            position: 'absolute',
            top: 32,
            left: '50%',
            transform: 'translate(-50%, 0)',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '16px',
          }}
        >
          <img
            src={'/images/JCDecaux.png'}
            style={{ width: 103, height: 40, marginRight: '32px' }}
          />
          <img src={'/images/Telekom.png'} style={{ width: 30, height: 36 }} />
        </div>
        <div
          id="button-container"
          style={{
            margin: 0,
            position: 'absolute',
            top: '55%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'calc(100% - 50px)',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontFamily: 'SourceSansProSemiBold',
                color: '#FFFFFF',
                fontSize: '26px',
                lineHeight: '36px',
                marginBottom: '32px',
              }}
            >
              Készíts egy szelfit, és zsebeld be az értékes nyereményeket!
            </div>

            <button
              style={{
                borderRadius: '72px',
                padding: '15px 28px',
                backgroundColor: '#FFFFFF',
                border: '2px solid #FFFFFF',
                color: '#002457',
                fontSize: '18px',
                lineHeight: '24px',
                fontFamily: 'Nunito',
              }}
              disabled={pressedStart}
              onClick={() => {
                setPressedStart(true)
                if (isAgreed) history.push('/g')
              }}
            >
              {isAgreed && pressedStart ? 'Betöltés...' : 'Játék megkezdése'}
            </button>
          </div>
        </div>
        <div
          id="footer"
          style={{
            position: 'absolute',
            bottom: '24px',
            margin: '0 24px',
            padding: '12px 16px',
            background: 'rgba(255, 255, 255)',
            color: '#000000',
            border:
              pressedStart && !isAgreed
                ? '2px solid #FF0505'
                : '2px solid #F5F5F5',
            borderRadius: '16px',
            fontFamily: 'SourceSansProLight',
            fontSize: '16px',
            lineHeight: '22px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              style={{ marginLeft: 0, accentColor: '#E10075' }}
              id="checkbox"
              type="checkbox"
              checked={isAgreed}
              onChange={(e) => {
                setIsAgreed(e.target.checked)
                if (e.target.checked) setPressedStart(false)
              }}
            />
            <label
              htmlFor="checkbox"
              style={{ display: 'flex', paddingLeft: '8px' }}
            >
              <div>
                Elolvastam és elfogadom a{' '}
                <a
                  style={{
                    color: 'inherit',
                    textUnderlineOffset: '3px',
                    textDecorationThickness: '1px',
                  }}
                  target="_blank"
                  href="http://keltsdeletre.hu/legal/Novella_jatekszabalyzat_allj_meg_egy_selfire_tisztazott.pdf"
                >
                  Játékszabályzatot
                </a>
                .
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
