import { useEffect, useState } from 'react'
import { BackgroundConfig } from '../helpers/backgroundHelper'
import { PostProcessingConfig } from '../helpers/postProcessingHelper'
import { SegmentationConfig } from '../helpers/segmentationHelper'
import { SourceConfig, SourcePlayback } from '../helpers/sourceHelper'
import { TFLite } from '../hooks/useTFLite'
import OutputViewer from './OutputViewer'
import SourceViewer from './SourceViewer'

type ViewerCardProps = {
  sourceConfig: SourceConfig
  backgroundConfig: BackgroundConfig
  segmentationConfig: SegmentationConfig
  postProcessingConfig: PostProcessingConfig
  onLoad: () => void
  tflite?: TFLite
  onImageReady?: (e: any) => void
}

function ViewerCard(props: ViewerCardProps) {
  const [sourcePlayback, setSourcePlayback] = useState<SourcePlayback>()

  useEffect(() => {
    setSourcePlayback(undefined)
  }, [props.sourceConfig])

  return (
    <div style={{ minHeight: '100%', width: '100%', position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          height: '100%',
          maxHeight: '-webkit-fill-available',
          width: '100%',
          opacity: 0,
        }}
      >
        <SourceViewer
          sourceConfig={props.sourceConfig}
          onLoad={(e) => {
            setSourcePlayback(e)
            props.onLoad()
          }}
        />
      </div>
      {sourcePlayback && props.tflite ? (
        <div>
          <OutputViewer
            sourcePlayback={sourcePlayback}
            backgroundConfig={props.backgroundConfig}
            segmentationConfig={props.segmentationConfig}
            postProcessingConfig={props.postProcessingConfig}
            tflite={props.tflite}
            onImageReady={props.onImageReady}
          />
        </div>
      ) : (
        <div>Nincs kép</div>
      )}
    </div>
  )
}

export default ViewerCard
