import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <div
            style={{
              margin: 0,
              position: 'absolute',
              top: '45%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div
              style={{
                width: '240px',
                textAlign: 'center',
                color: '#000000',
              }}
            >
              Betöltés...
            </div>
          </div>
        </div>
      }
    >
      <div id="font_preload" style={{ opacity: 0 }}>
        <span style={{ fontFamily: 'Nunito' }}></span>
        <span style={{ fontFamily: 'SourceSansProLight' }}></span>
        <span style={{ fontFamily: 'SourceSansProSemiBold' }}></span>
        <link rel="preload" href="/images/splash.png" as="image" />
        <link rel="preload" href="/images/book.png" as="image" />
        <link rel="preload" href="/images/ellipse.png" as="image" />
        <link rel="preload" href="/images/JCDecaux.png" as="image" />
        <link rel="preload" href="/images/Telekom.png" as="image" />
        <link rel="preload" href="/images/shutter.png" as="image" />
        <link rel="preload" href="/backgrounds/1.jpg" as="image" />
        <link rel="preload" href="/backgrounds/2.jpg" as="image" />
        <link rel="preload" href="/backgrounds/3.jpg" as="image" />
      </div>

      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
