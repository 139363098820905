import { useState, useEffect } from "react";

function getWindowDimensions() {
  if (typeof window !== "undefined" && document) {
    const { clientWidth: width, clientHeight: height } = document?.documentElement;
    return {
      width,
      height,
    };
  }
  return { width: 0, height: 0 };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowDimensions;
}
