import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import GameScreen from './screens/gameScreen'
import SplashScreen from './screens/splashScreen'

function App() {
  const [started, setStarted] = React.useState(false)
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact>
            <SplashScreen />
          </Route>
          <Route path="/g" exact>
            {!started && <SplashScreen />}
            <GameScreen onLoad={() => setStarted(true)} />
          </Route>
          <Route>
            <div>404</div>
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
