import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  BackgroundConfig,
  backgroundImageUrls,
} from '../helpers/backgroundHelper'
import React from 'react'

type BackgroundConfigCardProps = {
  config: BackgroundConfig
  onChange: (config: BackgroundConfig) => void
}

function BackgroundConfigCard(props: BackgroundConfigCardProps) {
  const [helpVisible, setHelpVisible] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => setHelpVisible(false), 5000)
  }, [])

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div style={{ paddingTop: '20px' }}>
        <Slider
          className="center"
          //slidesToShow={3}
          slidesToScroll={1}
          infinite={false}
          swipeToSlide
          arrows={false}
          focusOnSelect
          variableWidth
          centerMode
          afterChange={(e) =>
            props.onChange({ type: 'image', url: backgroundImageUrls[e] })
          }
        >
          {backgroundImageUrls.map((imageUrl, i) => (
            <div key={'set' + i}>
              <div
                style={{
                  margin: '12px',
                }}
              >
                <img
                  style={{
                    objectFit: 'cover',
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                  }}
                  src={imageUrl}
                  alt=""
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <img
        width={56}
        height={56}
        src={'/images/ellipse.png'}
        alt=""
        style={{
          position: 'absolute',
          top: 24,
          left: 'calc(50% - 28px)',
          pointerEvents: 'none',
        }}
      />
      {helpVisible && (
        <img
          width={180}
          height={48}
          src="/images/help.png"
          alt=""
          style={{
            position: 'absolute',
            top: 80,
            left: 'calc(50% - 90px)',
            pointerEvents: 'none',
          }}
        />
      )}
    </div>
  )
}

export default BackgroundConfigCard
