import { useEffect, useState } from 'react'
import BackgroundConfigCard from '../core/components/BackgroundConfigCard'
import ViewerCard from '../core/components/ViewerCard'
import {
  BackgroundConfig,
  backgroundImageUrls,
} from '../core/helpers/backgroundHelper'
import { PostProcessingConfig } from '../core/helpers/postProcessingHelper'
import { SegmentationConfig } from '../core/helpers/segmentationHelper'
import { SourceConfig } from '../core/helpers/sourceHelper'

import { createFileName } from 'use-react-screenshot'
import useTFLite from '../core/hooks/useTFLite'
import useWindowDimensions from '../shared/hooks/useWindowDimensions'

function GameSceen(props: any) {
  const [sourceConfig, setSourceConfig] = useState<SourceConfig>({
    type: 'camera',
  })
  const [backgroundConfig, setBackgroundConfig] = useState<BackgroundConfig>({
    type: 'image',
    url: backgroundImageUrls[0],
  })
  const [segmentationConfig, setSegmentationConfig] =
    useState<SegmentationConfig>({
      model: 'meet',
      backend: 'wasm',
      inputResolution: '160x96',
      pipeline: 'webgl2',
    })
  const [postProcessingConfig, setPostProcessingConfig] =
    useState<PostProcessingConfig>({
      smoothSegmentationMask: true,
      jointBilateralFilter: { sigmaSpace: 3, sigmaColor: 0.4 },
      coverage: [0.36, 0.7],
      lightWrapping: 0.07,
      blendMode: 'screen',
    })

  const { tflite, isSIMDSupported } = useTFLite(segmentationConfig)

  const { width, height } = useWindowDimensions()

  const [myImage, setMyImage] = useState('')

  useEffect(() => {
    setSegmentationConfig((previousSegmentationConfig) => {
      if (previousSegmentationConfig.backend === 'wasm' && isSIMDSupported) {
        return { ...previousSegmentationConfig, backend: 'wasmSimd' }
      } else {
        return previousSegmentationConfig
      }
    })
  }, [isSIMDSupported])

  const download = (image: any, { name = 'img', extension = 'jpg' } = {}) => {
    setMyImage(image)

    const a = document.createElement('a')
    a.href = image
    a.download = createFileName(extension, name)
    a.click()
  }

  return (
    <div
      style={{
        height: '100%',
        position: 'relative',
        background: 'black',
      }}
    >
      <ViewerCard
        sourceConfig={sourceConfig}
        backgroundConfig={backgroundConfig}
        segmentationConfig={segmentationConfig}
        postProcessingConfig={postProcessingConfig}
        tflite={tflite}
        onLoad={() => props.onLoad()}
        onImageReady={(e) => setMyImage(e)}
      />
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <BackgroundConfigCard
          config={backgroundConfig}
          onChange={setBackgroundConfig}
        />
      </div>
      {myImage && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '110%',
            background: 'white',
          }}
        >
          <div
            style={{ position: 'absolute', left: '16px', top: '16px' }}
            onClick={() => setMyImage('')}
          >
            <img src="images/backspace.png" width="24" height="24" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '24px',
            }}
          >
            <p
              style={{
                marginTop: '32px',
                marginBottom: '12px',
                color: '#002457',
                fontSize: '20px',
                lineHeight: '26px',
                fontFamily: 'Nunito',
              }}
            >
              Játék menete
            </p>
            <div
              style={{
                marginBottom: '48px',
                textAlign: 'center',
                color: '#181A27',
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'SourceSansProLight',
              }}
            >
              Töltsd fel az elkészült képet az “
              <a
                href="https://www.facebook.com/groups/1179619839485475/"
                style={{ color: '#317CD4' }}
              >
                Álljon meg egy novellára!
              </a>
              ” Facebook csoportba, hogy bekerülj a sorolásba!
              <br />
              <br />A feltöltés előtt csatlakoznod kell a csoporthoz!
            </div>
            <img
              src={myImage}
              width={width - 120}
              height="auto"
              style={{ marginBottom: '24px' }}
            ></img>
            <div style={{ marginBottom: '48px', width: '100%' }}>
              <button
                style={{
                  borderRadius: '72px',
                  padding: '15px 27px',
                  background:
                    'linear-gradient(91.97deg, rgba(255, 255, 255, 0.2) -6.18%, rgba(255, 255, 255, 0) 103.73%), #317CD4',
                  border: '3px solid #2572CC',
                  color: '#FFFFFF',
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontFamily: 'Nunito',
                  width: '100%',
                  marginBottom: '8px',
                }}
                onClick={() => {
                  //download(myImage)
                  fetch(myImage)
                    .then((res) => res.blob())
                    .then(async (blob) => {
                      const file = new File([blob], 'novella.jpeg', {
                        type: 'image/jpeg',
                      })
                      const data = {
                        files: [file],
                      }
                      try {
                        if (!navigator.canShare(data)) {
                          throw new Error("Can't share data.")
                        }
                        await navigator.share(data)
                      } catch (err) {}
                    })
                }}
              >
                1. Töltsd le és mentsd el a képet
              </button>
              <button
                style={{
                  borderRadius: '72px',
                  padding: '15px 27px',
                  background:
                    'linear-gradient(91.97deg, rgba(255, 255, 255, 0.2) -6.18%, rgba(255, 255, 255, 0) 103.73%), #317CD4',
                  border: '3px solid #2572CC',
                  color: '#FFFFFF',
                  fontSize: '18px',
                  lineHeight: '24px',
                  fontFamily: 'Nunito',
                  width: '100%',
                }}
                onClick={async () => {
                  window.open(
                    'https://www.facebook.com/groups/1179619839485475/'
                  )
                  /*fetch(myImage)
                    .then((res) => res.blob())
                    .then(async (blob) => {
                      const file = new File([blob], 'novella.jpeg', {
                        type: 'image/jpeg',
                      })
                      const data = {
                        files: [file],
                      }
                      try {
                        if (!navigator.canShare(data)) {
                          throw new Error("Can't share data.")
                        }
                        await navigator.share(data)
                      } catch (err) {}
                    })*/
                }}
              >
                2. Oszd meg a Fb csoportban
              </button>
            </div>
            <p
              style={{
                marginBottom: '12px',
                color: '#002457',
                fontSize: '20px',
                lineHeight: '26px',
                fontFamily: 'Nunito',
              }}
            >
              Nyeremények
            </p>
            <p
              style={{
                marginBottom: '48px',
                textAlign: 'center',
                color: '#181A27',
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'SourceSansProLight',
              }}
            >
              Játssz velünk, készíts egy szelfit, töltsd fel az Álljon meg egy
              novellára! facebook csoportba és nyerj egy 10 ezer forintos
              Rossmann vásárlási utalványt!
            </p>
            <p
              style={{
                marginBottom: '72px',
                textAlign: 'center',
                color: '#181A27',
                fontSize: '16px',
                lineHeight: '24px',
                fontFamily: 'SourceSansProLight',
              }}
            >
              A kép csoportba való feltöltésével elfogadod a{' '}
              <a
                href="http://keltsdeletre.hu/legal/Novella_jatekszabalyzat_allj_meg_egy_selfire_tisztazott.pdf"
                target="_blank"
                style={{ color: '#317CD4' }}
              >
                Játékszabályzatot
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default GameSceen
